/** 
 * Index for default customer 
*/
/* eslint-disable vue/multi-word-component-names */
/* eslint-disable vue/no-reserved-component-names */
import { createApp } from 'vue'
import App from '@/App.vue'
import CoreRouter from '@/core/router/router';
import VueGoogleMaps from '@fawmi/vue-google-maps'
import GerstlInvite from './components/Gerstl-Invite.vue';
import {
    Square3Stack3DIcon
} from '@heroicons/vue/24/outline';
import GerstlWebshop from './components/Gerstl-Webshop.vue';
import GerstlBlogPages from './components/Gerstl-Blog-Pages.vue';
import GerstlFrontpage from './components/Gerstl-Frontpage.vue';
import GerstlEventPages from './components/Gerstl-Event-Pages.vue';
import GerstlEventPages2 from './components/Gerstl-Events-Pagesv2.vue';
import GerstlFilterPages from './components/Gerstl-Filter-Pages.vue';
import GerstlVendorPages from './components/Gerstl-Vendor-Pages.vue';
import GerstlStaticPages from './components/Gerstl-Static-Pages.vue';
import GerstlPromocodes from "./components/Gerstl-Webshop-Promocodes.vue"
import GerstlPromocodesv2 from "./components/Gerstl-Promocodes.vue"
import GerstlShortlinks from "./components/Gerstl-Shortlinks.vue";
import GerstlWeinBroschueren from './components/Gerstl-Weinbroschueren.vue';
//import Settings from '@/core/components/User/Admin/Admin-User-Settings.vue'

import GerstlB2B from './components/Gerstl-B2B.vue';
import GerstlTeam from './components/Gerstl-Team.vue';
import GerstlStatistics from './components/Gerstl-Statistics.vue';
import GerstlSettings from './components/Gerstl-Settings.vue'; // @deprecated
import GerstlWeinalarm from './components/Gerstl-Weinalarm.vue';
import GerstlApiDocumentationV1 from './components/Gerstl-Api-Documentation-V1.vue';
import GerstlTools from './components/Gerstl-Tools.vue';
import DataHub from '@/core/components/User/Admin/Admin-Datahub.vue';
import {
    RouteAdd,
    RouteAddtoDashboard,
    DashboardAddLink
} from '@/core/var/router';
const customer = require('./config/config');
import '@/core/var/axios';
import { createPinia } from 'pinia'; const pinia = createPinia();
import { useCustomerStore } from '@/core/store/CustomerStore';
import { useAuthStore } from '@/core/store/AuthStore';
import '@/core/assets/primevue/neuraxis.css';
//import '/node_modules/primevue/resources/themes/mira/theme.css'
import 'primeicons/primeicons.css'

import PrimeVue from 'primevue/config'
import Tooltip from 'primevue/tooltip'
import ToastService from 'primevue/toastservice'
import ConfirmationService from 'primevue/confirmationservice'

import Image from 'primevue/image'
import Dialog from 'primevue/dialog'
import Button from 'primevue/button'
import InputText from 'primevue/inputtext'
import InputNumber from 'primevue/inputnumber'
import InputMask from 'primevue/inputmask'
import Toast from 'primevue/toast'
import Breadcrumb from 'primevue/breadcrumb'
import Menubar from 'primevue/menubar'
import Splitter from 'primevue/splitter'
import SplitterPanel from 'primevue/splitterpanel'
import Panel from 'primevue/panel'
import OrderList from 'primevue/orderlist'
import AutoComplete from 'primevue/autocomplete'
import OverlayPanel from 'primevue/overlaypanel'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Fieldset from 'primevue/fieldset'
import Divider from 'primevue/divider'
import Card from 'primevue/card'
import Badge from 'primevue/badge'
import BadgeDirective from 'primevue/badgedirective'
import Ripple from 'primevue/ripple'
import Dropdown from 'primevue/dropdown'
import MultiSelect from 'primevue/multiselect'
import ProgressSpinner from 'primevue/progressspinner'
import BlockUI from 'primevue/blockui'
import Chips from 'primevue/chips'
import StyleClass from 'primevue/styleclass'
import Textarea from 'primevue/textarea'
import Calendar from 'primevue/calendar'
import TreeSelect from 'primevue/treeselect'
import InputSwitch from 'primevue/inputswitch'
import TabView from 'primevue/tabview'
import TabPanel from 'primevue/tabpanel'
import Avatar from 'primevue/avatar'
import AvatarGroup from 'primevue/avatargroup'
import Chip from 'primevue/chip'
import Checkbox from 'primevue/checkbox'
import Tag from 'primevue/tag'
import SpeedDial from 'primevue/speeddial'
import Menu from 'primevue/menu'
import Sidebar from 'primevue/sidebar'
import Message from 'primevue/message'
import Accordion from 'primevue/accordion'
import AccordionTab from 'primevue/accordiontab'
import RadioButton from 'primevue/radiobutton'
import SelectButton from 'primevue/selectbutton'
import ConfirmDialog from 'primevue/confirmdialog'
import FileUpload from 'primevue/fileupload'
import ToggleButton from 'primevue/togglebutton'
import TreeTable from 'primevue/treetable'
import Editor from 'primevue/editor'
import SplitButton from 'primevue/splitbutton'
import Toolbar from 'primevue/toolbar'
import Galleria from 'primevue/galleria'
import ScrollPanel from 'primevue/scrollpanel'
import Chart from 'primevue/chart'

RouteAddtoDashboard({ name: 'Webshop', position: 'dashboard-left' }, GerstlWebshop, CoreRouter)
//RouteAddtoDashboard({ name: 'Promocodes', position: 'dashboard-left' }, GerstlPromocodes, CoreRouter)
RouteAddtoDashboard({ name: 'Promotionen', position: 'dashboard-left' }, GerstlPromocodesv2, CoreRouter)
RouteAddtoDashboard({ name: 'Shortlinks', position: 'dashboard-left' }, GerstlShortlinks, CoreRouter)
RouteAddtoDashboard({ name: 'Magazin', position: 'dashboard-left' }, GerstlBlogPages, CoreRouter)
RouteAddtoDashboard({ name: 'Events', position: 'dashboard-left' }, GerstlEventPages, CoreRouter)
RouteAddtoDashboard({ name: 'Eventsv2', position: 'dashboard-left' }, GerstlEventPages2, CoreRouter)
RouteAddtoDashboard({ name: 'Weinbroschüren', position: 'dashboard-left' }, GerstlWeinBroschueren, CoreRouter)
RouteAddtoDashboard({ name: 'Filter-Seiten', position: 'dashboard-left' }, GerstlFilterPages, CoreRouter)
RouteAddtoDashboard({ name: 'Produzenten', position: 'dashboard-left' }, GerstlVendorPages, CoreRouter)
RouteAddtoDashboard({ name: 'Seiten', position: 'dashboard-left' }, GerstlStaticPages, CoreRouter)
RouteAddtoDashboard({ name: 'Weinalarm', position: 'dashboard-left' }, GerstlWeinalarm, CoreRouter)
RouteAddtoDashboard({ name: 'Team', position: 'dashboard-left' }, GerstlTeam, CoreRouter)
RouteAddtoDashboard({ name: 'Statistiken', position: 'dashboard-left' }, GerstlStatistics, CoreRouter)
RouteAddtoDashboard({ name: 'Einstellungen', position: 'dashboard-left' }, GerstlSettings, CoreRouter)
RouteAddtoDashboard({ name: 'Tools', position: 'dashboard-left' }, GerstlTools, CoreRouter)
RouteAddtoDashboard({ name: 'Startseite', position: 'dashboard-left' }, GerstlFrontpage, CoreRouter)
RouteAddtoDashboard({ name: 'B2B', position: 'dashboard-left' }, GerstlB2B, CoreRouter)

const gerstl_invite_link = 'invite'
RouteAdd(gerstl_invite_link, GerstlInvite, CoreRouter)

const gerstl_apiv1_link = 'API-Documentation-V1'
RouteAdd(gerstl_apiv1_link, GerstlApiDocumentationV1, CoreRouter)

const data_hub_link = 'datahub'
RouteAdd(data_hub_link, DataHub, CoreRouter)

DashboardAddLink({ name: 'Data Hub', href: `/${data_hub_link}`, icon: Square3Stack3DIcon, order: 10 })

const neuraxisApp = createApp(App);

neuraxisApp.use(pinia);
const customerStore = useCustomerStore();
customerStore.setCustomer(customer);

const auth = useAuthStore();

neuraxisApp.use(CoreRouter);

neuraxisApp.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyBN_QVsSvrACqMWCnQgUxN-_51_Cvbvt1k',
        language: 'de',
    },
})

//optional
neuraxisApp.use(PrimeVue, { ripple: true });
neuraxisApp.use(ToastService);
neuraxisApp.use(ConfirmationService);
neuraxisApp.directive('Tooltip', Tooltip);
neuraxisApp.directive('badge', BadgeDirective);
neuraxisApp.directive('ripple', Ripple);
neuraxisApp.directive('styleclass', StyleClass);

neuraxisApp.component('Image', Image);
neuraxisApp.component('Dialog', Dialog);
neuraxisApp.component('InputText', InputText)
neuraxisApp.component('Button', Button)
neuraxisApp.component('Toast', Toast)
neuraxisApp.component('ConfirmDialog', ConfirmDialog)
neuraxisApp.component('Breadcrumb', Breadcrumb)
neuraxisApp.component('Menubar', Menubar)
neuraxisApp.component('Splitter', Splitter)
neuraxisApp.component('SplitterPanel', SplitterPanel)
neuraxisApp.component('Panel', Panel)
neuraxisApp.component('OrderList', OrderList)
neuraxisApp.component('AutoComplete', AutoComplete)
neuraxisApp.component('OverlayPanel', OverlayPanel)
neuraxisApp.component('DataTable', DataTable)
neuraxisApp.component('Column', Column)
neuraxisApp.component('Fieldset', Fieldset)
neuraxisApp.component('Divider', Divider)
neuraxisApp.component('Card', Card)
neuraxisApp.component('Badge', Badge)
neuraxisApp.component('Dropdown', Dropdown)
neuraxisApp.component('MultiSelect', MultiSelect)
neuraxisApp.component('ProgressSpinner', ProgressSpinner)
neuraxisApp.component('BlockUI', BlockUI)
neuraxisApp.component('InputNumber', InputNumber)
neuraxisApp.component('Chips', Chips)
neuraxisApp.component('Textarea', Textarea)
neuraxisApp.component('Calendar', Calendar)
neuraxisApp.component('TreeSelect', TreeSelect)
neuraxisApp.component('InputSwitch', InputSwitch)
neuraxisApp.component('TabView', TabView)
neuraxisApp.component('TabPanel', TabPanel)
neuraxisApp.component('Avatar', Avatar)
neuraxisApp.component('AvatarGroup', AvatarGroup)
neuraxisApp.component('Chip', Chip)
neuraxisApp.component('Checkbox', Checkbox)
neuraxisApp.component('InputMask', InputMask)
neuraxisApp.component('Tag', Tag)
neuraxisApp.component('SpeedDial', SpeedDial)
neuraxisApp.component('Menu', Menu)
neuraxisApp.component('Sidebar', Sidebar)
neuraxisApp.component('Message', Message)
neuraxisApp.component('Accordion', Accordion)
neuraxisApp.component('AccordionTab', AccordionTab)
neuraxisApp.component('RadioButton', RadioButton)
neuraxisApp.component('SelectButton', SelectButton)
neuraxisApp.component('FileUpload', FileUpload)
neuraxisApp.component('ToggleButton', ToggleButton)
neuraxisApp.component('TreeTable', TreeTable)
neuraxisApp.component('Editor', Editor)
neuraxisApp.component('SplitButton', SplitButton)
neuraxisApp.component('Toolbar', Toolbar)
neuraxisApp.component('Galleria', Galleria)
neuraxisApp.component('ScrollPanel', ScrollPanel)
neuraxisApp.component('Chart', Chart)

neuraxisApp.mount('#app');